import {
  Card,
  IconButton,
  CardContent,
  Box,
  Typography,
  CardActions,
  Button,
  styled,
} from "@mui/material";
import React, { useContext } from "react";
import { ProfileContext } from "../../contexts/ProfileContext";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import ProfileDocumentDeleteDialog from "./ProfileDocumentDeleteDialog";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { returnFileExtension } from "../../Utils";

const CustomCard = styled(Card)({
  width: "215px",
  "&:hover .icons": {
    opacity: 1,
  },
});

const Icon = styled(IconButton)({
  opacity: 0,
  transition: "all 0.3s ease",
});

const ProfileDocumentItem: React.FC<any> = ({ file }) => {
  const { t } = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const { peopleData, setPeopleData } = useContext(ProfileContext);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (file: any) => {
    window.open(file.contentUrl, "_blank");
  };

  const handleDelete = async () => {
    setOpen(false);
    try {
      await axios.delete(file["@id"].split("/api/")[1]);
      // Le document à bien été supprimé - passe à la suite
    } catch (err) {
      console.log("ERROR", err);
      // Une erreur est survenue lors de la suppression de ce document - ne passe pas à la suite
      return;
    }

    const files = [...peopleData.files];
    files.splice(
      files.findIndex((el: any) => el["@id"] === file["@id"]),
      1
    );
    setPeopleData({ ...peopleData, files });
  };

  return (
    <CustomCard key={file["@id"]} sx={{ mr: 2, mb: 2, position: "relative" }}>
      {file.createdPersonId === tokenData.person_id && (
        <Icon
          className="icons"
          aria-label="close"
          onClick={() => setOpen(true)}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <CancelIcon color="error" />
        </Icon>
      )}
      <CardContent sx={{ textAlign: "center" }}>
        {file.thumbnail?.contentUrl && (
          <Box position="absolute" sx={{ top: 10, left: 10 }}>
            <img src={returnFileExtension(file)} width="30" alt="extension" />
          </Box>
        )}
        <Box display="flex" justifyContent="center" sx={{ height: "75px" }}>
          {file.thumbnail?.contentUrl ? (
            <img
              style={{ width: "55px", height: "auto" }}
              alt={file.name}
              src={file.thumbnail?.contentUrl}
            />
          ) : (
            <img src={returnFileExtension(file)} alt="extension" />
          )}
        </Box>
        <Box
          sx={{
            mt: 1,
            maxWidth: "25ch",
            height: "30px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {file.name}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button size="small" onClick={() => handleOpen(file)}>
          {t("btn.open")}
        </Button>
      </CardActions>
      <ProfileDocumentDeleteDialog
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </CustomCard>
  );
};

export default ProfileDocumentItem;
